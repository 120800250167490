/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css');
@import url('https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.2.1/css/fontawesome.min.css');
@import '~font-awesome/css/font-awesome.css';
@import '~@fortawesome/fontawesome-free/css/fontawesome';
@import '~@fortawesome/fontawesome-free/css/solid';
@import '~@fortawesome/fontawesome-free/css/regular';


@import 'ngx-toastr/toastr';

/* globely styles start */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge*/
  user-select: none;
}

.search-icon{
  color: #0d3066;
}

.example-section {
  padding: 10px 15px 0px
}

/* globely  styles end */
/* globely body styles start */
body {
  height: 92.5vh;
  width: 100%;
  transition: all ease-in-out 1s;
}

.btn :focus {
  outline: none !important;
  box-shadow: none !important;
}

.buttons {
  display: flex;
}

.close-icon {
  cursor: pointer;
  width: 15px;
  z-index: 999
}



.btn-primary,
.btn-success {
  font-weight: 500 !important;

}

thead th {
  font-weight: 600 !important;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {

  font-size: 16px/28px !important;
  font-family: 'poppins', "Helvetica Neue", sans-serif !important;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px/28px poppins, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.drawflow_content_node h3 {
  position: absolute;
  top: 11px;
  /* left: 0px; */
  background: #936464;
  border-radius: 5px;
  padding: 0px 6px;
  color: white;
  font-size: 22px !important;
  right: 20px;
}


::ng-deep .mat-menu-panel {
  width: 100%;
  max-width: 300px;
  min-height: auto !important;
  /* or any other maximum width you prefer */
}

::ng-deep .mat-menu-content {
  white-space: nowrap !important;
}

.firmware-config {
  text-transform: inherit !important;
}



#spinner {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.ngx-pagination .current {
  background: #126CD8 !important;
  border-radius: 10px;
}

a {
  text-decoration: none !important;
}



/* globely body styles end */
.main-header h1 {
  font-weight: bold !important;
  font-family: Poppins !important;
  margin: 0 !important;
}

h1 {
  text-transform: capitalize;
  font-weight: 500;
  margin: 0 !important;

}

#more-vert {
  font-size: 20px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 5px;
  height: 6.5px;
}


::-webkit-scrollbar-thumb {

  background: rgba(40, 108, 161, 0.584);
  border-radius: 20px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(40 108 161);
  border-radius: 20px;
  cursor: pointer;
}

tr:nth-child(odd) td {
  background: #f7f8fc;
}

tr:nth-child(even) td {
  background: #fff;
}

/* thead {
  border-bottom: 1px solid #3f51b5 !important;
} */

th {
  background-color: hwb(0deg 100% 0%) !important;

}

th[_ngcontent-wmh-c289]:hover {
  background-color: none !important;
}

.data-info {
  font-weight: 400 !important;
}

td {
  border-bottom: none !important;
  padding: 15px 0px 15px 3px !important;
}

table {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

/* tr td:nth-child(1){
   background-color: #ffffff !important;
 } */
tr td:nth-child(2) {
  font-weight: bold !important;
}

.ngx-pagination .current {
  background: #126cd8 !important;
}

/* td{
   background: none !important;
 }  */
@media only screen and (max-width:1280px) {
  body {
    height: 93.2vh;
  }
}

@media only screen and (max-width:1200px) {
  body {
    height: 91vh;
  }
}

@media only screen and (max-width:820px) {
  body {
    height: 95vh;
  }
}

/* @media only screen and (max-width:1180px) {
   body{
    height:93.5vh;
   }
  } */
@media only screen and (max-width:425px) {
  body {
    height: 93vh;
  }
}

::ng-deep .mat-tab-label-active {
  border-bottom: 2px solid #3b52ac !important;
  transition: 1s;
  opacity: 1 !important;
}

::ng-deep .mat-tab-label:focus:not(.mat-tab-disabled) {
  opacity: 1 !important;

}

::ng-deep.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: white !important;
}

::ng-deep .mat-checkbox-layout {
  display: block !important
}

.form-control:disabled {
  background-color: #e5f2ff !important;
}

.col-md-12 {
  padding: 0;
}

.col-md-6 {
  padding: 0;
}

.fa-solid {
  cursor: pointer;
}

.mat-menu-item {
  width: 100% !important;
}

.mat-menu-panel {
  width: fit-content !important;
  min-height: auto !important;


  /* routes css  */



}

:root {
  /* --border-color: #4ea9ff; */
  --color: #000000;
  --background-color: #ffffff;
  --background-box-title: #444;
}

/* html,
body {
  margin: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  color: #000;
} */

header {
  /* height: 66px; */
  border-bottom: 1px solid var(--border-color);
  padding-left: 20px;
}

header h2 {
  margin: 0px;
  line-height: 66px;
}

header a {
  color: black;
}

.them-edit-link {
  position: absolute;
  top: 10px;
  right: 100px;
  color: black;
  font-size: 40px;
}

.them-edit-link a {
  text-decoration: none;
}

.github-link {
  position: absolute;
  top: 10px;
  right: 20px;
  color: black;
}

.routes-wrapper {
  width: 100%;
  height: calc(100vh - 67px);
  display: flex;
  background: white;
}

.col {
  /* overflow: auto;
  width: 227px;
  height: 100%;
  border-right: 1px solid var(--border-color);
  padding: 0px 8px !important; */
  overflow: auto;
  width: 100%;
  /* height: 92%; */
  max-height: 92%;
  min-height: auto;
  border-right: 1px solid var(--border-color);
  padding: 0px 8px !important;
}

.drag-drawflow {
  line-height: 50px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  /* margin-top: 10px; */
  margin: 10px 10px 0px 0px;
  /* border-bottom: 1px solid var(--border-color); */
  padding-left: 20px;
  cursor: move;
  user-select: none;
}

.overflow {
  position: fixed;
  padding: 9px;
  font-size: 20px;
  background: white;
  cursor: pointer;
}

.nav-right {
  top: 66px;
  right: 195px;
}

.menu {
  position: absolute;
  display: block;
  background: var(--color);
  overflow-x: auto;
  /* width: 85%; */
  width: 21%;
  margin:2px  10px;
  left: 0;
  /* margin-right: 186px; */
  background-color: var(--background-color);
  overflow-y: hidden;
}

.menu ul {
  margin: 0px;
  padding: 0px 30px;
  display: flex;
  width: max-content;
  line-height: 40px;
}

.menu ul li {
  display: inline-block;
  margin-left: 10px;
  /* border-right: 1px solid var(--border-color); */
  padding-right: 10px;
  line-height: 40px;
  cursor: pointer;
}

.menu ul li.selected {
  font-weight: bold;
}

.btn-clear {
  float: right;
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--color);
  font-weight: bold;
  border: 1px solid #0e5ba3;
  background: var(--border-color);
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 5;
}

.btn-action {
  float: right;
  position: absolute;
  top: 10px;
  right: 156px;
  color: var(--color);
  font-weight: bold;
  border: 1px solid #0e5ba3;
  background: var(--border-color);
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 5;
}

.btn-lock {
  float: right;
  position: absolute;
  bottom: 10px;
  right: 140px;
  display: flex;
  font-size: 24px;
  color: var(--color);
  padding: 5px 10px;
  background: #555555;
  border-radius: 4px;
  border-right: 1px solid var(--border-color);
  z-index: 5;
  cursor: pointer;
}

.bar-zoom {
  float: right;
  position: absolute;
  bottom: 10px;
  cursor: pointer;
  gap: 10px;
  right: 10px;
  display: flex;
  font-size: 15px;
  color: #ffffff;
  padding: 5px 10px;
  background: #0d3066;
  border-radius: 4px;
  border-right: 1px solid var(--border-color);
  z-index: 5;
}

.bar-zoom svg {
  cursor: pointer;
  padding-left: 10px;
}

.bar-zoom svg:nth-child(1) {
  padding-left: 0px;
}

#drawflow {
  position: relative;
  width: calc(100vw - 301px);
  height: calc(100% - 50px);
  top: 40px;
  background: var(--background-color);
  background-image: linear-gradient(to right, #f1f1f1 1px, transparent 1px), linear-gradient(to bottom, #f1f1f1 1px, transparent 1px);
}

.drawflow .drawflow-node .inputs,
.drawflow .drawflow-node .outputs {
  width: 0px;
}

/* Editing Drawflow */
.drawflow .drawflow-node {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  background: var(--background-color);
  border: 1px solid var(--border-color);
  -webkit-box-shadow: 0 2px 15px 2px var(--border-color);
  box-shadow: 0 2px 15px 2px var(--border-color);
  padding: 0px;
  width: 200px;
  color: var(--color);
}

.drawflow .drawflow-node.selected {
  background: var(--color);
  border: 1px solid var(--border-color);
  -webkit-box-shadow: 0 2px 20px 2px var(--border-color);
  box-shadow: 0 2px 20px 2px var(--border-color);
}

.drawflow .drawflow-node.selected .title-box {
  color: black;
  background: white;

  border-bottom: 1px solid var(--border-color);
}

.drawflow .connection .main-path {
  stroke: #4ea9ff;
  stroke-width: 3px;
}

.drawflow .drawflow-node .input,
.drawflow .drawflow-node .output {
  height: 15px;
  width: 15px;
  border: 2px solid var(--border-color);
}

.drawflow .drawflow-node .input:hover,
.drawflow .drawflow-node .output:hover {
  background: var(--border-color);
}

.drawflow .drawflow-node .output {
  right: 10px;
}

.drawflow .drawflow-node .input {
  left: -10px;
  background: var(--color);
}

.drawflow>.drawflow-delete {
  border: 2px solid #43b993;
  background: var(--color);
  color: #43b993;
  -webkit-box-shadow: 0 2px 20px 2px #43b993;
  box-shadow: 0 2px 20px 2px #43b993;
}

.drawflow-delete {
  border: 2px solid var(--border-color);
  background: var(--color);
  color: var(--border-color);
  -webkit-box-shadow: 0 2px 20px 2px var(--border-color);
  box-shadow: 0 2px 20px 2px var(--border-color);
}

.drawflow-node .title-box {
  height: 50px;
  line-height: 50px;
  display: flex;
  gap: 10px;
  align-items: baseline;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding-left: 10px;
}

.drawflow .title-box svg {
  position: initial;
}

.drawflow-node .box {
  padding: 10px 20px 20px 20px;
  font-size: 14px;
  color: #000000;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  /* color: var(--color); */
}

.drawflow-node .box p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.drawflow .drawflow-node.selected .box {
  color: #000000;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.drawflow-node.welcome {
  width: 250px;
}

.drawflow-node.slack .title-box {
  border-radius: 4px;
}

.drawflow-node input,
.drawflow-node select,
.drawflow-node textarea {
  border-radius: 4px;
  border: 1px solid #4ea9ff;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  width: 158px;
  color: #555555;
}

.drawflow-node textarea {
  height: 100px;
}

.drawflow-node.personalized {
  background: red;
  height: 200px;
  text-align: center;
  color: var(--color);
}

.drawflow-node.personalized .input {
  background: yellow;
}

.drawflow-node.personalized .output {
  background: green;
}

.drawflow-node.personalized.selected {
  background: blue;
}

.drawflow .connection .point {
  stroke: #4ea9ff;
  stroke-width: 2;
  fill: var(--color);
  transform: translate(-9999px, -9999px);
}




.drawflow .connection .point.selected,
.drawflow .connection .point:hover {
  fill: #4ea9ff;
}

/* Modal */
.modal {
  display: none;
  position: fixed;
  z-index: 7;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  /* background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.7); */
  background-color: #333;
  color: #eee;
}

.modal-content {
  position: relative;
  /* background-color: #fefefe; */
  background-color: #333;
  color: #eee;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 400px;
  /* Could be more or less, depending on screen size */
}

/* The Close Button */

.modal .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .modal-content {
    width: 80%;
  }
}

/* -------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------- */

.drawflow,
.drawflow .parent-node {
  position: relative;
}

.parent-drawflow {
  display: flex;
  overflow: hidden;
  touch-action: none;
  outline: 0;
}

.drawflow {
  width: 100%;
  height: 100%;
  user-select: none;
  position: relative;
  user-select: none;
  perspective: 0;
}

.drawflow .drawflow-node:hover {
  cursor: move;
}

.drawflow .drawflow-node .inputs,
.drawflow .drawflow-node .outputs {
  width: 0;
}

.drawflow .drawflow-node .drawflow_content_node {
  width: 100%;
  display: block;
}

.drawflow .drawflow-node .input,
.drawflow .drawflow-node .output {
  position: relative;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid #000;
  cursor: crosshair;
  z-index: 1;
  margin-bottom: 5px;
}

.drawflow .drawflow-node .input {
  left: -11px;
  top: 2px;
  background: paleturquoise;
}

.drawflow .drawflow-node .output {
  right: 5px;
  top: 2px;
}

.drawflow svg {
  z-index: 0;
  position: absolute;
  overflow: visible !important;
}

/* Default style for .drawflow .connection */
.drawflow .connection {
  position: absolute;
  transform: translate(9999px, 9999px);
}



.drawflow .connection .main-path {
  fill: none;
  stroke-width: 5px;
  stroke: #4ea9ff;
  transform: translate(-9999px, -9999px);
}

.drawflow .connection .main-path:hover {
  stroke: #1266ab;
  cursor: pointer;
}

.drawflow .connection .main-path.selected {
  stroke: #43b993;
}

.drawflow .connection .point {
  cursor: move;
  stroke: #000;
  stroke-width: 2;
  fill: #fff;
  transform: translate(-9999px, -9999px);
}

.drawflow .connection .point.selected,
.drawflow .connection .point:hover {
  fill: #1266ab;
}

.drawflow .main-path {
  fill: none;
  stroke-width: 5px;
  stroke: #4ea9ff;
}

.drawflow .connection {
  position: absolute;
  pointer-events: none;
  aspect-ratio: 1 / 1;
}

.drawflow .selectbox {
  z-index: 3;
  position: absolute;
  transform: translate(9999px, 9999px);
}

.drawflow .selectbox rect {
  fill: #00f;
  opacity: 0.5;
  stroke: #ff0;
  stroke-width: 5;
  stroke-opacity: 0.5;
  transform: translate(-9999px, -9999px);
}

.drawflow-delete {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  background: #000;
  color: #fff;
  z-index: 4;
  border: 2px solid #fff;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  font-family: monospace;
  cursor: pointer;
}

.drawflow>.drawflow-delete {
  margin-left: -15px;
  margin-top: 15px;
}

.parent-node .drawflow-delete {
  right: -15px;
  top: -15px;
}

.drawflow-node .output:hover {
  background: #00faff !important;
}

.drawflow-node .input:hover {
  background: #3bc631 !important;
}



.drawflow_content_node h6 {
  position: absolute;
  top: 13px;
  /* left: 0px; */
  background: #40c5b8;
  border-radius: 5px;
  padding: 1px 7px;
  color: white;
  font-size: 15px !important;
  right: 20px;
}

.drawflow_content_node h5 {
  position: absolute;
  top: 13px;
  /* left: 0px; */
  background: #40a2c5;
  border-radius: 5px;
  padding: 1px 7px;
  color: white;
  /* width: 20px; */
  /* height: 20px; */
  font-size: 15px;
  right: 20px;
}

.drawflow_content_node h4 {
  position: absolute;
  top: 13px;
  /* left: 0px; */
  background: #4076c5;
  border-radius: 5px;
  padding: 1px 7px;
  color: white;
  /* width: 20px; */
  /* height: 20px; */
  font-size: 15px;
  right: 20px;
}

.routes-nav {
  height: 0;
  justify-content: flex-end;
}

/* input tel library  */

ngx-intl-tel-input input {
  height: 44px;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-size: 18px;
}

ngx-intl-tel-input input {
  border: none;
  /* border-bottom: 1px solid #949494 !important; */
  border-radius: 0px;
  width: 100%;
}


ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

/* border-bottom: 1px solid #949494;
width: 528px;


position: absolute;
    z-index: 1;
    left: 9px;
    color: black;
    color: 0px;
    opacity: 0.7;
    top: -7px;

    position: relative; */

.iti {
  display: block !important;

}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;

}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 453px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2) !important;
  max-height: 179px;
}

.iti__flag-container.open+input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2) !important;
}

.iti .search-container input:focus {
  outline: none;
}

/* Add a red border to the element */
.shake-border {
  border: 1px solid red !important;
}

/* Define the shake animation */
@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

/* Add a red border to the element */
.shake-border {
  border: 1px solid red !important;
}

/* Define the shake animation */
@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

/* Apply the shake animation to the element */
.shake-animation {
  animation-name: shake;
  animation-duration: 0.5s;
}

/* Combine the border and animation */
.shake-border.shake-animation {
  animation-timing-function: ease-in-out;
}

.reading-block {
  overflow: initial !important;
}

.signup-num .iti--allow-dropdown {
  margin-top: 13px !important;
  margin-left: -8px;
}

/* overflow-y: auto;
height: 25vh;
overflow-x: hidden; */
.tooltips .tooltip-inner {

  font-size: 13px;
}




.drawflow .connection .main-path {
  fill: none;
  stroke-width: 5px;
  stroke: steelblue;
  pointer-events: all;
}

.drawflow .connection .main-path:hover {
  stroke: #1266ab;
  cursor: pointer;
}

.drawflow .connection .main-path {
  fill: none;
  stroke-width: 5px;
  stroke: #4ea9ff;
  pointer-events: all;
}

.drawflow .connection .main-path:hover {
  stroke: #1266ab;
  cursor: pointer;
}

.drawflow .connection .main-path.selected {
  stroke: #43b993;
}

.drawflow .connection .point {
  cursor: move;
  stroke: black;
  stroke-width: 2;
  fill: white;
  pointer-events: all;
}

.drawflow .connection .point.selected,
.drawflow .connection .point:hover {
  fill: #1266ab;
}

.drawflow .main-path {
  fill: none;
  stroke-width: 5px;
  stroke: steelblue;
}

.drawflow-delete {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  background: black;
  color: white;
  z-index: 4;
  border: 2px solid white;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  font-family: monospace;
  cursor: pointer;
}

.drawflow>.drawflow-delete {
  margin-left: -15px;
  margin-top: 15px;
}

.parent-node .drawflow-delete {
  right: -15px;
  top: -15px;
}

.dark-mode {
  /* Define your dark mode styles here */
  background-color: #333;
  color: #fff;
}

#minimap {
  border: 1px solid red;
  width: 150px;
  height: 150px;
  position: absolute;
  top: 67px;
  left: 820px;
  cursor: move;
}

#minimap * {
  cursor: move;
  pointer-events: none;
}

#minimap .mask {
  border: 1px solid red;
  border-radius: 4px;
  position: absolute;
  background: rgb(245, 111, 111);
  opacity: 0.3;
  pointer-events: none;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}.myPanelClasss{
  max-height: 215px !important;
}

textarea{
  resize: none;
}

